@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }

html {
  background-color: #f0f0f0; }
  html :not(.contractBox) {
    font-family: 'Nunito', sans-serif; }

.packageInfo, .clientForm .section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  width: 60%;
  margin-top: 3em;
  border-bottom: 2px solid #e1e1e1;
  overflow: hidden;
  max-width: 900px; }

@font-face {
  font-family: azonix;
  src: url(/static/media/Azonix.cdfe47b3.otf); }

.flex {
  display: flex;
  align-items: center; }

.align-cen {
  align-items: center; }

.justCon-cen {
  justify-content: center; }

.row {
  flex-direction: row; }

.column {
  flex-direction: column; }

.logoType {
  font-family: azonix !important;
  font-size: 1.2em; }

.logo {
  padding: 1em;
  width: 50px; }

.marTop {
  margin-top: 50px; }

.titleBar {
  width: 100%;
  color: white;
  text-transform: uppercase;
  background: #60ccfa;
  background: -webkit-gradient(left top, right top, color-stop(0%, #60ccfa), color-stop(51%, #6180fc), color-stop(100%, #8e60fa));
  background: -webkit-gradient(linear, left top, right top, from(#60ccfa), color-stop(51%, #6180fc), to(#8e60fa));
  background: linear-gradient(to right, #60ccfa 0%, #6180fc 51%, #8e60fa 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#60ccfa', endColorstr='#8e60fa', GradientType=1 );
  margin: 0;
  padding: 12px;
  border-radius: 4px; }

.feature {
  display: flex;
  align-items: center;
  color: black;
  border-radius: 4px;
  font-size: 1.2em;
  flex-direction: row;
  width: 80%;
  padding: 4px; }
  .feature:last-child {
    margin-right: 0; }
  .feature p {
    margin: 10px;
    margin-left: 0;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    background-color: #ebebeb; }
  .feature .label {
    font-weight: bold;
    background-color: #8e60fa;
    border-bottom: 2px solid #855ff0;
    color: white; }

#paypallCheckout {
  width: 80%; }

.small {
  font-size: 0.9em; }

.clientForm {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .clientForm .section:last-child {
    padding-bottom: 0; }
  .clientForm .section {
    margin: 0;
    margin-top: 10px;
    padding-bottom: 2em;
    /* background-color: white;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px; */ }
    .clientForm .section h1 {
      margin: 0;
      margin-bottom: 10px;
      text-align: center; }
    .clientForm .section h2 {
      margin: 1em; }
  .clientForm .field {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left; }
    .clientForm .field label {
      margin-bottom: 4px;
      margin-top: 4px;
      font-weight: bold; }
    .clientForm .field input {
      border: 1px solid black;
      border-radius: 4px;
      background-color: #f5f5f5;
      height: 1.8em;
      width: 100%;
      padding-left: 4px;
      font-size: 1em;
      color: black; }
      .clientForm .field input:hover {
        background-color: white; }

.formError {
  color: red;
  margin-left: 1em;
  font-size: 0.9em;
  font-weight: bold; }

.contractBox {
  font-family: unset !important;
  width: 90%;
  height: 600px;
  overflow: scroll;
  border-radius: 4px;
  margin: 8px;
  background-color: #f7f7f7;
  max-width: 900px;
  padding: 1em;
  text-align: left;
  overflow-x: hidden; }

.bankBox {
  padding: 1em;
  margin: 1em; }

.btnBar {
  margin-top: 2em; }

.buttonBlue, .buttonCheckout {
  border-radius: 4px;
  color: white;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  text-align: center;
  background-color: #8e60fa;
  padding: 12px;
  font-weight: bold; }
  .buttonBlue svg, .buttonCheckout svg {
    fill: white !important; }
  .buttonBlue:hover, .buttonCheckout:hover {
    background-color: #6180fc;
    cursor: pointer; }

.buttonCheckout {
  box-sizing: border-box;
  background-color: blue;
  width: 80%;
  margin-top: 25px;
  padding: 20px; }

.wide {
  width: 100%; }

.collapsedDiv {
  height: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
  opacity: 0;
  pointer-events: none; }

.txt-left {
  text-align: left; }

.showDiv {
  height: 170px;
  padding: 1em;
  margin: 1em;
  opacity: 1;
  pointer-events: unset; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.overLay {
  background-color: rgba(0, 0, 0, 0.51);
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  position: fixed; }

.spinner, .miniSpinner {
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  top: 50%; }
  .spinner *, .miniSpinner * {
    fill: #60ccfa; }
  .spinner svg, .miniSpinner svg {
    -webkit-animation-timing-function: cubic-bezier(0.7, 1, 0.1, 0.1);
            animation-timing-function: cubic-bezier(0.7, 1, 0.1, 0.1);
    -webkit-transform-origin: 50% 40%;
            transform-origin: 50% 40%;
    font-size: 10em;
    width: 10vw;
    height: 10vw;
    -webkit-animation-name: spin;
            animation-name: spin;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }

.miniSpinner {
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
  position: static;
  left: 0;
  top: 0;
  -webkit-transform: unset;
          transform: unset; }
  .miniSpinner svg {
    width: inherit;
    height: inherit; }

.footer {
  width: 100%; }

@media (max-width: 768px) {
  .feature {
    width: 100%;
    flex-direction: column; }
    .feature p {
      width: 70%; }
  .clientForm {
    width: 80%; }
    .clientForm field {
      width: 70%; }
  .packageInfo, .clientForm .section {
    width: 80%;
    margin-left: 10%; }
  .spinner svg, .miniSpinner svg {
    width: 30vw;
    height: 30vw; } }

@media (max-width: 460px) {
  .clientForm {
    width: 100%; }
    .clientForm .field {
      width: 90%; }
  .feature {
    width: 100%; }
  .packageInfo, .clientForm .section {
    margin-top: 1em;
    width: 100%;
    margin-left: 0%; } }

@font-face {
  font-family: SourceSansPro;
  src: url(/static/media/SourceSansPro-Regular.ba6cad25.ttf); }

.contract .clearfix:after {
  content: "";
  display: table;
  clear: both; }

.contract a {
  color: blue;
  text-decoration: underline;
  font-weight: bold; }

.contract body {
  position: relative;
  width: 21cm;
  height: 29.7cm;
  margin: 0 auto;
  color: #000000;
  background: #FFFFFF;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-family: SourceSansPro; }

.contract header {
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #AAAAAA; }

.contract #logo {
  float: left;
  margin-top: 8px; }

.contract #logo img {
  height: 70px; }

.contract #company {
  float: right;
  text-align: right; }

.contract #details {
  margin-bottom: 50px; }

.contract #client {
  padding-left: 6px;
  border-left: 6px solid #000000;
  float: left; }

.contract #client .to {
  color: #000000; }

.contract h2.name {
  font-size: 1.4em;
  font-weight: normal;
  margin: 0; }

.contract #invoice {
  float: right;
  text-align: right; }

.contract #invoice h1 {
  color: #000000;
  font-size: 2.4em;
  line-height: 1em;
  font-weight: normal;
  margin: 0 0 10px 0; }

.contract #invoice .date {
  font-size: 1.1em;
  color: #000000; }

.contract #firma\ lobesoft {
  float: top;
  margin-top: 30px; }

.contract #firma\ lobesoft img {
  height: 130px; }
  .contract #firma\ lobesoft img #thanks {
    font-size: 2em;
    margin-bottom: 50px; }
  .contract #firma\ lobesoft img #notices {
    padding-left: 6px;
    border-left: 6px solid #000000; }
  .contract #firma\ lobesoft img #notices .notice {
    font-size: 1.2em; }
  .contract #firma\ lobesoft img footer {
    color: #000000;
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #000000;
    padding: 8px 0;
    text-align: center; }

