.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}


@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

html {
  background-color: #f0f0f0;
  :not(.contractBox){
    font-family: 'Nunito', sans-serif;
  }

}

.packageInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  width: 60%;
  margin-top: 3em;
  border-bottom: 2px solid #e1e1e1;
  overflow: hidden;
  max-width: 900px;
}

@font-face {
  font-family: azonix;
  src: url("fonts/Azonix.otf");
}

.flex {
  display: flex;
  align-items: center;
}

.align-cen{
  align-items: center;
}

.justCon-cen {
  justify-content: center;
}
.row {
 flex-direction: row;
}

.column {
    flex-direction: column;
}

.logoType {
  font-family: azonix!important;
  font-size: 1.2em;
}

.logo {
  padding: 1em;
  width: 50px;
}

.marTop {
  margin-top: 50px;
}

.titleBar {
  width: 100%;
  color: white;
  text-transform: uppercase;
  background: rgba(96,204,250,1);
  background: -moz-linear-gradient(left, rgba(96,204,250,1) 0%, rgba(97,128,252,1) 51%, rgba(142,96,250,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(96,204,250,1)), color-stop(51%, rgba(97,128,252,1)), color-stop(100%, rgba(142,96,250,1)));
  background: -webkit-linear-gradient(left, rgba(96,204,250,1) 0%, rgba(97,128,252,1) 51%, rgba(142,96,250,1) 100%);
  background: -o-linear-gradient(left, rgba(96,204,250,1) 0%, rgba(97,128,252,1) 51%, rgba(142,96,250,1) 100%);
  background: -ms-linear-gradient(left, rgba(96,204,250,1) 0%, rgba(97,128,252,1) 51%, rgba(142,96,250,1) 100%);
  background: linear-gradient(to right, rgba(96,204,250,1) 0%, rgba(97,128,252,1) 51%, rgba(142,96,250,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#60ccfa', endColorstr='#8e60fa', GradientType=1 );
  margin: 0;
  padding: 12px;
  border-radius: 4px;
}

.feature {
  display: flex;
  align-items: center;
  color: black;
  border-radius: 4px;
  font-size: 1.2em;
  flex-direction: row;
  width: 80%;
  padding: 4px;

  &:last-child{
    margin-right: 0;
  }

  p {
    margin: 10px;
    margin-left: 0;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    background-color: #ebebeb;
  }

  .label {
    font-weight: bold;
    background-color: #8e60fa;
    border-bottom: 2px solid #855ff0;
    color: white;
  }

}

#paypallCheckout {
  width: 80%;
}

.small {
  font-size: 0.9em;
}

.clientForm {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .section:last-child {
    padding-bottom: 0;
  }

  .section {
    @extend .packageInfo;
    margin: 0;
    margin-top: 10px;
    padding-bottom: 2em;

    h1 {
      margin: 0;
      margin-bottom: 10px;
      text-align: center;
    }

    h2{
      margin: 1em;
    }

   /* background-color: white;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px; */
  }

  .field {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: left  ;

    label{
      margin-bottom: 4px;
      margin-top: 4px;
      font-weight: bold;
    }

    input {
      border: 1px solid black;
      border-radius: 4px;
      background-color: #f5f5f5;
      height: 1.8em;
      width: 100%;
      padding-left: 4px;
      font-size: 1em;
      color: black;

      &:hover {
        background-color: white;
      }
    }
  }
}

.formError {
  color: red;
  margin-left: 1em;
  font-size: 0.9em;
  font-weight: bold;
}

.contractBox {
  font-family: unset!important;
  width: 90%;
  height: 600px;
  overflow: scroll;
  border-radius: 4px;
  margin: 8px;
  background-color: #f7f7f7;
  max-width: 900px;
  padding: 1em;
  text-align: left;
  overflow-x: hidden;
}

.bankBox {
  padding: 1em;
  margin: 1em;
}

.btnBar {
  margin-top: 2em;
}

.buttonBlue {
  border-radius: 4px;
  color: white;
  transition: background-color 0.3s;
  text-align: center;
  background-color: #8e60fa;
  padding: 12px;
  font-weight: bold;

  svg {
    fill: white!important;
  }

  &:hover {
    background-color: #6180fc;
    cursor: pointer;
  }
}

.buttonCheckout {
  @extend .buttonBlue;
  box-sizing: border-box;
  background-color: blue;
  width: 80%;
  margin-top: 25px;
  padding: 20px;
}

.wide {
  width: 100%;
}

.collapsedDiv {
  height: 0;
  transition: all 0.3s;
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.txt-left {
  text-align: left;
}

.showDiv {
  height: 170px;
  padding:1em;
  margin: 1em;
  opacity: 1;
  pointer-events: unset;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overLay {
  background-color: rgba(0, 0, 0, 0.51);
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  position: fixed;
}

.spinner {
  *{
    fill: #60ccfa;
  }

  position: fixed;
  transform: translate(-50%,-50%);
  left: 50%;
  top:50%;

  svg {
    animation-timing-function: cubic-bezier(0.7, 1.0, 0.1, 0.1);
    transform-origin: 50% 40%;
    font-size: 10em;
    width: 10vw;
    height: 10vw;
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

.miniSpinner {
  @extend .spinner;
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
  position: static;
  left: 0;
  top: 0;
  transform: unset;

  svg {
    width: inherit;
    height: inherit;
  }
}

.footer {
  width: 100%;
}

@media (max-width: 768px) {
  .feature {
    width: 100%;
    flex-direction: column;

    p {width: 70%}
  }

  .clientForm {
    width: 80%;
    field {
      width: 70%;
    }
  }

  .packageInfo {
    width: 80%;
    margin-left: 10%;
  }

  .spinner {
    svg {
      width: 30vw;
      height: 30vw;
    }
  }
}

@media (max-width: 460px) {
  .clientForm {
    width: 100%;
    .field {
      width: 90%;
    }
  }

  .feature {
    width: 100%;
  }

  .packageInfo {
    margin-top: 1em;
    width: 100%;
    margin-left: 0%;
  }
}