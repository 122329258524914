@font-face {
    font-family: SourceSansPro;
    src: url(fonts/SourceSansPro-Regular.ttf);
}

.contract {
    .clearfix:after {
        content: "";
        display: table;
        clear: both;
    }

    a {
        color: blue;
        text-decoration: underline;
        font-weight: bold;
    }

    body {
        position: relative;
        width: 21cm;
        height: 29.7cm;
        margin: 0 auto;
        color: #000000;
        background: #FFFFFF;
        font-family: Arial, sans-serif;
        font-size: 14px;
        font-family: SourceSansPro;
    }

    header {
        padding: 10px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid #AAAAAA;
    }

    #logo {
        float: left;
        margin-top: 8px;
    }

    #logo img {
        height: 70px;
    }

    #company {
        float: right;
        text-align: right;
    }


    #details {
        margin-bottom: 50px;
    }

    #client {
        padding-left: 6px;
        border-left: 6px solid #000000;
        float: left;
    }

    #client .to {
        color: #000000;
    }

    h2.name {
        font-size: 1.4em;
        font-weight: normal;
        margin: 0;
    }

    #invoice {
        float: right;
        text-align: right;
    }

    #invoice h1 {
        color: #000000;
        font-size: 2.4em;
        line-height: 1em;
        font-weight: normal;
        margin: 0 0 10px 0;
    }

    #invoice .date {
        font-size: 1.1em;
        color: #000000;
    }

    #firma\ lobesoft {
        float: top;
        margin-top: 30px;
    }

    #firma\ lobesoft img {
        height: 130px;

        #thanks {
            font-size: 2em;
            margin-bottom: 50px;
        }

        #notices {
            padding-left: 6px;
            border-left: 6px solid #000000;
        }

        #notices .notice {
            font-size: 1.2em;
        }

        footer {
            color: #000000;
            width: 100%;
            height: 30px;
            position: absolute;
            bottom: 0;
            border-top: 1px solid #000000;
            padding: 8px 0;
            text-align: center;
        }
    }
}

